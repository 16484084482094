import React, { useCallback, useContext, useEffect, useState } from 'react';
import { getClient, setClient } from 'utils/cognitesdk';
import { CogniteClient, OnAuthenticateLoginObject } from '@cognite/sdk';
import * as Sentry from '@sentry/browser';
import { AuthProvider } from '@cognite/react-container';
import { useMetrics, Metrics, PerfMetrics } from '@cognite/metrics';
import { SIDECAR } from 'utils/sidecar';
import { useDispatch } from 'react-redux';
import authSlice from 'features/auth';
import { getTenant } from 'utils/tenancy';
import config from 'utils/config';
import { reportException } from '@cognite/react-errors';
import { SDKProvider } from '@cognite/sdk-provider';
import { ProjectContext } from './ProjectContext';

type Props = {
  children: React.ReactNode;
};

const AuthContainer = ({ children }: Props) => {
  const { authState, client: authProviderClient } = useContext(AuthProvider);
  const { docsSiteBaseUrl, cdfApiBaseUrl, applicationId } = SIDECAR;
  const [authenticating, setAuthenticating] = useState(true);
  const dispatch = useDispatch();

  const tenant = getTenant();

  const { token, email, project: authProject, authenticated } = authState || {};

  const project = authProject || authProviderClient?.project || '';

  const metrics = useMetrics('AuthContainer', {
    project,
  });

  const onAuthenticate = useCallback(
    (loginObj: OnAuthenticateLoginObject) => {
      if (!loginObj) {
        throw new Error('Missing login object on the auth screen');
      }

      loginObj.redirect({
        redirectUrl: window.location.href,
        errorRedirectUrl: docsSiteBaseUrl,
      });
    },
    [docsSiteBaseUrl]
  );

  useEffect(() => {
    const doLogin = async () => {
      if (!token || !authenticating) {
        return;
      }

      try {
        setAuthenticating(true);

        if (authenticated && token && email) {
          const nextClient = new CogniteClient({
            appId: applicationId,
            project,
            getToken: () => Promise.resolve(token),
            baseUrl: cdfApiBaseUrl,
          });

          // @ts-ignore
          setClient(authProviderClient || nextClient);

          Metrics.identify(email || 'not-identified');
          PerfMetrics.initialize(
            `${SIDECAR.frontendMetricsBaseUrl}/${tenant}`,
            token,
            tenant,
            applicationId
          );

          if (
            !process.env.REACT_APP_PROMETHEUS_DEBUG &&
            (config.env === 'development' || config.env === 'preview')
          ) {
            PerfMetrics.disable();
          }

          if (email) {
            dispatch(
              authSlice.actions.login({
                user: email,
                project,
              })
            );
            Metrics.people({
              $email: email,
              name: email,
            });

            Sentry.setUser({
              email,
            });
          }
        }
      } catch (ex) {
        reportException(ex as Error);
        metrics.track('AuthContainer', ex);
      } finally {
        setAuthenticating(false);
      }
    };

    doLogin();
  }, [
    project,
    authenticating,
    token,
    authenticated,
    email,
    onAuthenticate,
    metrics,
    dispatch,
    applicationId,
    cdfApiBaseUrl,
    authProviderClient,
    tenant,
  ]);

  if (authenticating) {
    return null;
  }

  return (
    <ProjectContext.Provider value={{ project }}>
      <SDKProvider sdk={getClient()}>{children}</SDKProvider>
    </ProjectContext.Provider>
  );
};

export default AuthContainer;

import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import useNavigation from 'utils/useNavigation';
import { subtractTimezoneOffset, getEndOfLocalDay } from 'utils/datetime';
import timeRangeSlice, { TimeRangeState } from './reducer';

export const useTimeRange = () => {
  const {
    start,
    end,
    summaryPageStart,
    summaryPageEnd,
    exportStart,
    exportEnd,
  } = useSelector<RootState, TimeRangeState>((state) => {
    return state.timeRange;
  });

  const dispatch = useDispatch();
  const { isSummaryPage } = useNavigation();
  const setTimeRange = useCallback(
    (start: number, newEnd: number) => {
      const end = subtractTimezoneOffset(getEndOfLocalDay(newEnd));
      if (isSummaryPage) {
        dispatch(
          timeRangeSlice.actions.adjustSummaryPage({
            start,
            end,
          })
        );
      } else {
        dispatch(timeRangeSlice.actions.adjust({ start, end }));
      }
    },
    [dispatch, isSummaryPage]
  );
  const setExportTime = useCallback(
    (start: number, end: number) => {
      dispatch(
        timeRangeSlice.actions.adjustExport({
          start,
          end,
        })
      );
    },
    [dispatch]
  );
  const slide = (direction: 'forwards' | 'backwards') => {
    dispatch(timeRangeSlice.actions.slide(direction));
  };

  return {
    start: isSummaryPage ? summaryPageStart : start,
    end: isSummaryPage ? summaryPageEnd : end,
    setTimeRange,
    slideTimeWindow: slide,
    exportStart,
    exportEnd,
    setExportTime,
  };
};

export type Sidecar = {
  __sidecarFormatVersion: number;
  applicationId: string;
  applicationName: string;
  cdfCluster: string;
  appsApiBaseUrl: string;
  cdfApiBaseUrl: string;
  docsSiteBaseUrl: string;
  nomaApiBaseUrl: string;
  bestdayApiBaseUrl: string;
  cogniteChartsBaseUrl: string;
  userManagementServiceBaseUrl: string;
  privacyPolicyUrl: string;
  unleash: string;
  frontendMetricsBaseUrl: string;
  fusionApiBaseUrl: string;
};

// These variables are only used locally.
// Sidecar will override everything on staging / prod envs.
const { REACT_APP_UNLEASH_API_TOKEN } = process.env;

const generateBaseUrls = (prod = false) => {
  if (prod) {
    return {
      __sidecarFormatVersion: 1,
      airApiBaseUrl: 'https://air-api.omv.cognite.ai',
      aadApplicationId: '90efc725-162b-4cfc-887d-e42a5d1bf280',
      cdfCluster: 'omv',
      applicationId: 'bestday',
      applicationName: 'Best Day',
      appsApiBaseUrl: 'https://apps-api.omv.cognite.ai',
      bestdayApiBaseUrl: 'https://bestday-api.omv.cognite.ai',
      calculationServiceBaseUrl:
        'https://calculation-service.development.cognite.ai',
      cogniteChartsBaseUrl: 'https://charts.omv.cogniteapp.com',
      commentServiceBaseUrl: 'https://comment-service.omv.cognite.ai',
      contextServiceBaseUrl: 'https://context-service.development.cognite.ai',
      dataQualityMonitoringApiBaseUrl:
        'https://data-quality-monitoring-api.development.cognite.ai',
      digitalCockpitApiBaseUrl: 'https://digital-cockpit-api.omv.cognite.ai',
      discoverApiBaseUrl: 'https://discover-api.omv.cognite.ai',
      docsSiteBaseUrl: 'https://docs.cognite.com',
      frontendMetricsBaseUrl: 'https://frontend-metrics.omv.cognite.ai',
      infieldApiBaseUrl: 'https://infield-api.omv.cognite.ai',
      infieldCacheApiBaseUrl: 'https://infield-cache-api.omv.cognite.ai',
      mpServiceBaseURL: 'https://maintenance-planner-service.omv.cognite.ai',
      openaiProxyBaseUrl: 'https://api.omv.cogniteapp.com/openai-proxy',
      powerOpsApiBaseUrl: 'https://power-ops-api.omv.cognite.ai',
      privacyPolicyUrl: 'https://www.cognite.com/en/policy',
      remoteApiBaseUrl: 'https://api.omv.cogniteapp.com/remote-api',
      seismicApiBaseUrl: 'https://seismic-api.development.cognite.ai',
      simconfigApiBaseUrl: 'https://simconfig-api.omv.cognite.ai',
      snifferServiceBaseUrl: 'https://sniffer-service.omv.cognite.ai',
      unleash: REACT_APP_UNLEASH_API_TOKEN,
      userManagementServiceBaseUrl:
        'https://user-management-service.omv.cognite.ai',
    };
  }
  return {
    __sidecarFormatVersion: 1,
    airApiBaseUrl: 'https://air-api.staging.omv.cognite.ai',
    cdfCluster: 'omv',
    aadApplicationId: '60d60c48-4fe8-4c2b-87fe-557ca7b65489',
    applicationId: 'bestday-staging',
    applicationName: 'Best Day (staging)',
    appsApiBaseUrl: 'https://apps-api.staging.omv.cognite.ai',
    bestdayApiBaseUrl: 'https://bestday-api.staging.omv.cognite.ai',
    calculationServiceBaseUrl:
      'https://calculation-service.development.cognite.ai',
    cogniteChartsBaseUrl: 'https://charts.staging.omv.cogniteapp.com',
    commentServiceBaseUrl: 'https://comment-service.staging.omv.cognite.ai',
    contextServiceBaseUrl: 'https://context-service.development.cognite.ai',
    dataQualityMonitoringApiBaseUrl:
      'https://data-quality-monitoring-api.development.cognite.ai',
    digitalCockpitApiBaseUrl:
      'https://digital-cockpit-api.staging.omv.cognite.ai',
    discoverApiBaseUrl: 'https://discover-api.staging.omv.cognite.ai',
    docsSiteBaseUrl: 'https://docs.cognite.com',
    frontendMetricsBaseUrl: 'https://frontend-metrics.staging.omv.cognite.ai',
    infieldApiBaseUrl: 'https://infield-api.staging.omv.cognite.ai',
    infieldCacheApiBaseUrl: 'https://infield-cache-api.staging.omv.cognite.ai',
    mpServiceBaseURL:
      'https://maintenance-planner-service.staging.omv.cognite.ai',
    openaiProxyBaseUrl: 'https://api.staging.omv.cogniteapp.com/openai-proxy',
    powerOpsApiBaseUrl: 'https://power-ops-api.staging.omv.cognite.ai',
    privacyPolicyUrl: 'https://www.cognite.com/en/policy',
    remoteApiBaseUrl: 'https://api.staging.omv.cogniteapp.com/remote-api',
    seismicApiBaseUrl: 'https://seismic-api.development.cognite.ai',
    simconfigApiBaseUrl: 'https://simconfig-api.staging.omv.cognite.ai',
    snifferServiceBaseUrl: 'https://sniffer-service.staging.omv.cognite.ai',
    unleash: REACT_APP_UNLEASH_API_TOKEN,
    userManagementServiceBaseUrl:
      'https://user-management-service.staging.omv.cognite.ai',
  };
};

const PROD = process.env.REACT_APP_ENV === 'production';

export const SIDECAR: Sidecar = {
  ...generateBaseUrls(PROD),
  __sidecarFormatVersion: 0,
  enableUserManagement: true,
  fusionApiBaseUrl: `https://omv.fusion.cognite.com`,
  cdfApiBaseUrl: `https://omv.cognitedata.com`,
  // We should always have a token
  unleash: process.env.REACT_APP_UNLEASH_API_TOKEN || '',
  /* eslint-disable @typescript-eslint/no-explicit-any */
  // eslint-disable-next-line no-underscore-dangle
  ...(window as any).__cogniteSidecar,
} as const;

// eslint-disable-next-line no-underscore-dangle
(window as any).__cogniteSidecar = SIDECAR;

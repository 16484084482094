/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  addDateByUnitType,
  exportStartDefault,
  getDiffByUnitType,
  now,
  subtractDateByUnitType,
} from 'utils/datetime';

const timeRangeSlice = createSlice({
  name: 'timeRange',
  initialState: {
    start: 0,
    end: 0,
    summaryPageStart: 0,
    summaryPageEnd: 0,
    exportStart: exportStartDefault,
    exportEnd: +now,
  },
  reducers: {
    adjust: (
      state,
      { payload: { start, end } }: PayloadAction<{ start: number; end: number }>
    ) => {
      state.start = start;
      state.end = end;
    },

    adjustSummaryPage: (
      state,
      { payload: { start, end } }: PayloadAction<{ start: number; end: number }>
    ) => {
      state.summaryPageStart = start;
      state.summaryPageEnd = end;
    },
    adjustExport: (
      state,
      { payload: { start, end } }: PayloadAction<{ start: number; end: number }>
    ) => {
      state.exportStart = start;
      state.exportEnd = end;
    },

    slide: (
      state,
      { payload: direction }: PayloadAction<'forwards' | 'backwards'>
    ) => {
      const diffDays = getDiffByUnitType(state.end, state.start, 'd') || 1;
      state.start =
        direction === 'backwards'
          ? +subtractDateByUnitType(state.start, diffDays, 'd')
          : +addDateByUnitType(state.start, diffDays, 'd');
      state.end =
        direction === 'backwards'
          ? +subtractDateByUnitType(state.end, diffDays, 'd')
          : +addDateByUnitType(state.end, diffDays, 'd');
    },
  },
});

export type TimeRangeState = ReturnType<typeof timeRangeSlice.reducer>;

export default timeRangeSlice;
